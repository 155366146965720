import '../css/AddAddress.css'
import * as React from 'react';
import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import SimpleAlert from '../components/SimpleAlert';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import { useForm } from "react-hook-form";
import useCsrfToken from '../useCsrfToken';

const defaultTheme = createTheme();

export default function AddAddress(){
    const [loader, setLoader] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');
    const csrfToken = useCsrfToken();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const registerOptions = {
        street: {
            required: "Street is required",
            maxLength: {
                value: 100,
                message: "Street cannot exceed 100 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9\s,]+$/,
                message: "Street should only contain letters"
            }
        },
        state: {
            required: "State is required",
            minLength: {
                value: 2,
                message: "State must be at least 2 characters"
            },
            maxLength: {
                value: 50,
                message: "State cannot exceed 50 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message: "State should only contain letters"
            }
        },
        city: {
            required: "City is required",
            maxLength: {
                value: 50,
                message: "City cannot exceed 50 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message: "City should only contain letters"
            }
        },
        building: {
            required: "Building is required",
            maxLength: {
                value: 50,
                message: "Building cannot exceed 50 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message: "Building should only contain letters"
            }
        },
        floor: {
            validate: {
                isInteger: value => Number.isInteger(Number(value)) || "Floor must be an integer",
                min: value => value >= 0 || "Floor must be 0 or higher"
            }
        },
        extra_info: {
            maxLength: {
                value: 200,
                message: "Extra info cannot exceed 200 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message: "Extra info should only contain letters"
            }
        }
    };

    const handleAddAddress = async (formData) => {
        setLoader(true);
        try {
            const { street, state, city, building, floor, extra_info } = formData;
            const response = await fetch("/api/user/add-address", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'CSRF-Token': csrfToken
                },
                body: JSON.stringify({ address: { street, state, city, building, floor, extra_info } }),
            });
            const data = await response.json();
            setAlertMsg(data.message)
            if (data.success) {
                setAlertType('success');
            } else {
                setAlertType('error');
            }
        } catch (error) {
            setAlertType('error');
            setAlertMsg("An error occured");
        }
        setLoader(false);
    };

    function Copyright(props) {
        return (
          <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="/">
              Pharmacy
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
    }    

    return (
        <div id='addaddress-bigcontainer' style={{marginTop: '5%'}}>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <SimpleAlert message={alertMsg} type={alertType} display={alertMsg !== '' ? 'block' : 'none'}/>
                <Container className='addaddress-container' key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                    <CssBaseline />
                    <Box
                        key="box"
                        sx={{
                            marginTop: 5,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: "green" }}>
                            <HomeIcon />
                        </Avatar>
                        <Typography key="sign-in-title" component="h2" variant="h5">
                            Add address
                        </Typography>
                        <Box key="form-box" component="form" onSubmit={handleSubmit(handleAddAddress)} noValidate sx={{ mt: 3 }}>
                            <Grid key="grid-container" container spacing={2}>
                                <Grid key="cvv-field" item xs={12} sm={6}>
                                    <TextField
                                        key="street-input"
                                        required
                                        fullWidth
                                        name="street"
                                        label="Street"
                                        id="street"
                                        {...register("street", registerOptions.street)}
                                    />
                                    <small className="text-danger">
                                        {errors?.street && errors.street.message}
                                    </small>
                                </Grid>
                                <Grid key="state-field" item xs={12} sm={6}>
                                    <TextField
                                        key="state-input"
                                        required
                                        fullWidth
                                        name="state"
                                        label="State"
                                        id="state"
                                        {...register("state", registerOptions.state)}
                                    />
                                    <small className="text-danger">
                                        {errors?.state && errors.state.message}
                                    </small>
                                </Grid>
                                <Grid key="city-field" item xs={12} sm={6}>
                                    <TextField
                                        key="city-input"
                                        required
                                        fullWidth
                                        name="city"
                                        label="City"
                                        id="city"
                                        {...register("city", registerOptions.city)}
                                    />
                                    <small className="text-danger">
                                        {errors?.city && errors.city.message}
                                    </small>
                                </Grid>
                                <Grid key="building-field" item xs={12} sm={6}>
                                    <TextField
                                        key="building-input"
                                        required
                                        fullWidth
                                        name="building"
                                        label="Building"
                                        id="building"
                                        {...register("building", registerOptions.building)}
                                    />
                                    <small className="text-danger">
                                        {errors?.building && errors.building.message}
                                    </small>
                                </Grid>
                                <Grid key="floor-field" item xs={12} sm={6}>
                                    <TextField
                                        key="floor-input"
                                        required
                                        fullWidth
                                        name="floor"
                                        label="Floor"
                                        id="floor"
                                        {...register("floor", registerOptions.floor)}
                                    />
                                    <small className="text-danger">
                                        {errors?.floor && errors.floor.message}
                                    </small>
                                </Grid>
                                <Grid key="extrainfo-field" item xs={12} sm={6}>
                                    <TextField
                                        key="extrainfo-input"
                                        fullWidth
                                        name="extra_info"
                                        label="Extra info"
                                        id="extra_info"
                                        {...register("extra_info", registerOptions.extra_info)}
                                    />
                                    <small className="text-danger">
                                        {errors?.extra_info && errors.extra_info.message}
                                    </small>
                                </Grid>
                            </Grid>
                            <Button
                                key="saveaddr-button"
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, bgcolor: "green" }}
                            >
                                Save
                            </Button>
                        </Box>
                        <div style={{marginTop: '20px', marginBottom: '20px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                </Container>
                <Copyright key="Pharmacy" sx={{ mt: 5 }} />
            </ThemeProvider>
        </div>
    )
}