import '../css/Brand.css';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ProductItem from "../components/ProductItem";
import Cookies from 'js-cookie';
import SimpleAlert from '../components/SimpleAlert';
import CircularIndeterminate from '../components/CircularLoad';
import google_menu from '../assets/google_menu.svg'
import google_close from '../assets/google_close.svg'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Skeleton from '@mui/material/Skeleton';

export default function Brand() {
    const { brand } = useParams();
    const [data, setData] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showMoreDisplay, setShowMoreDisplay] = useState(false);
    const [language, setLanguage] = useState('english');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [error, setError] = useState(null);
    const [options, setOptions] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [priceRange, setPriceRange] = useState([0, maxPrice]); // Initial price range
    const [isCollapsed, setIsCollapsed] = useState(true);
    const productsPerPage = 12;

    useEffect(() => {
        fetchInitialProducts();
    }, [brand]);

    const fetchInitialProducts = async () => {
        try {
            if (Cookies.get('language')) {
                setLanguage(Cookies.get('language'));
            } else {
                Cookies.set('language', 'english');
                setLanguage('english');
            }
            await fetchProductsByBrand(brand);
        } catch (error) {
            console.error('Error fetching initial products:', error);
            setError('Failed to fetch initial products.');
        }
    };

    const fetchProductsByBrand = async (brand) => {
        try {
            const response = await fetch(`/api/brand/${brand}?totalProducts=${totalProducts}`);
            if (response.ok) {
                const products = await response.json();
                setLoading(false);
                setTotalProducts(prevTotalProducts => prevTotalProducts + products.length);
                setData(prevData => [...prevData, ...products]);
                setMaxPrice(Math.max(...products.map(product => product.price)));
                setPriceRange([0, Math.max(...products.map(product => product.price))]);
                setShowMoreDisplay(false)
            } else {
                console.error('Failed to fetch products');
                setError('Failed to fetch products.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to fetch products.');
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue); // Update the selected price range
    };    

    const filteredData = data.filter(product =>
       product.price >= priceRange[0] && product.price <= priceRange[1]
    );    

    function getMax(product) {
        if(product.price > maxPrice) {
            setMaxPrice(product.price)
            console.log(product.price)
            console.log('maxprice', maxPrice)
        }
    }

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const categories = {
        'pain_killers': (language === 'arabic') ? 'مسكنات الألم' : 'Pain killers',
        'vitamins': (language === 'arabic') ? 'الفيتامينات' : 'Vitamins',
        'colds': (language === 'arabic') ? 'سعال البرد' : 'Cold/cough',
        'body_care': (language === 'arabic') ? 'العناية بالجسم' : 'Body care',
        'hygiene': (language === 'arabic') ? 'صحة' : 'Hygiene',
        'first_aid': (language === 'arabic') ? 'إسعافات أولية' : 'First aid'
    };

    return (
        <div dir={language === 'arabic' ? 'rtl' : 'ltr'} className='brandpage-container' style={{marginLeft: isCollapsed ? '' : '0', marginRight: isCollapsed ? '' : '0'}}>
            <h2 style={{color: 'green', margin: 0}} id='brand-h2'>{brand.substring(0, 1).toUpperCase() + brand.substring(1, brand.length)}</h2>
            <aside className={`side-bar ${isCollapsed ? 'collapsed' : ''}`}>
                <div style={{display: 'flex', marginBottom: '15px'}}>
                    <h3 style={{color: 'green', margin: 0}}>Catgories</h3>
                    <button onClick={toggleSidebar}>
                        <img src={google_close}></img>
                    </button>
                </div>
                <div className='sidebar-ul'>
                    <ul>
                        {Object.entries(categories).map(([catKey, catName]) => (
                            <li key={catKey}>
                                <a href={`/product/category/${catKey}`}>
                                    {catName}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <hr />
                    <hr />
                    <div id="price-filter" style={{marginLeft: '5%', marginRight: '5%', marginTop: '2.5%'}}>
                        <h3 style={{ color: 'green', fontWeight: '300' }}>
                            {language === 'english' ? 'Price' : 'الماركات'}
                        </h3>
                        <Box>
                            <span></span>
                            <Slider
                                value={priceRange}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={maxPrice}
                                step={1}
                                marks
                                sx={{ color: 'green' }}
                            />
                        </Box>
                    </div>
                </div>
            </aside>
            <div className='mobile-brandmenu' style={{ opacity: isCollapsed ? 1 : 0.5}}>
                <button onClick={toggleSidebar}>
                    <img src={google_menu}></img>
                </button>
                {/* <h2 id='category-h2'>{brand}</h2> */}
            </div>
            <section className={`big-brand-container ${isCollapsed ? '' : 'bigcontainer-opacity'}`} style={{ opacity: isCollapsed ? 1 : 0.5}}>
                <SimpleAlert id="addcart-alert" key="alert" message="Added to cart" type='success'/>
                {/* <aside id="listAside">
                    <h5 style={{ color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300' }}>
                        {language === 'arabic' ? 'فئات' : 'Categories'}
                    </h5>
                    <ul>
                        {Object.entries(categories).map(([catKey, catName]) => (
                            <li key={catKey}>
                                <a href={`/product/category/${catKey}`}>
                                    {catName}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <hr></hr>
                    <div id="price-filter" style={{marginLeft: '5%', marginRight: '5%', marginTop: '2.5%'}}>
                        <h5 style={{ color: 'green', fontWeight: '300' }}>
                            {language === 'english' ? 'Price' : 'الماركات'}
                        </h5>
                        <Box>
                            <span></span>
                            <Slider
                                value={priceRange}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={maxPrice}
                                step={1}
                                marks
                                sx={{ color: 'green' }}
                            />
                        </Box>
                    </div>
                </aside> */}
                <aside id="productsAside">
                    <div className="brand-container">
                        {Array.from({ length: totalProducts }).map((_, index) => (
                            <Box key={index} sx={{ pt: 0.5, display: loading ? 'block' : 'none' }}>
                                <Skeleton variant="rectangular" width={210} height={118} />
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        ))}
                        {error ? (
                            <div>Error: {error}</div>
                        ) : (
                            filteredData.map(product => (
                                <ProductItem key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language}/>
                            ))
                        )}
                    </div>
                    <div id='brand-pagination'>
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                color="primary"
                            />
                        </Stack>
                    </div>
                </aside>
            </section>
        </div>
    );
}