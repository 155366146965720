export const logout = async () => {
    try {
        const response = await fetch('/api/user/logout');
        if (response.ok) {
            sessionStorage.setItem('sessionMessage', 'You have been logged out');
            window.location.reload();
        } else {
            console.error("Logout failed");
        }
    } catch (error) {
        console.error('Error:', error);
    }
};