export const removeFromWishlist= async (productID, csrfToken, callback) => {
    try {
        const response = await fetch('/api/user/wishlist', {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'CSRF-Token': csrfToken
            },
            body: JSON.stringify({ productID })
        });
        const data = await response.json();
        if (data.success) {
            callback();
        } else {
            // error notify user
        }
    } catch (error) {
        // error notify user
    }
};

export const clearWishlist = async (csrfToken, callback) => {
    try {
      const response = await fetch('/api/user/clearWishlist', {
        method: "PUT",
        headers: {
          'CSRF-Token': csrfToken
        },
        credentials: 'include'
      });
      const data = await response.json();
      if (data.success) {
          callback();
      } else {
        // error notify user
      }
    } catch (error) {
      // error notify user
    }
};