import '../css/Home.css'
import { useEffect, useState, useRef } from "react";
import Category from "../components/Category"
import panadol from '../assets/panadol-extra.avif';
import vitamins from '../assets/centrum.jpg';
import colds from '../assets/cold-cough.jpg';
import body_care from '../assets/dove.avif';
import hygiene from '../assets/head_shoulders.jpg';
import first_aid from '../assets/first_aid.jpg';
import vichy_logo from '../assets/vichy_logo.png';
import advil from '../assets/Advil_logo.png';
import centrum_logo from '../assets/centrum_logo.jpeg';
import panadol_logo from '../assets/panadol-logo.jpeg';
import head_shoulders from '../assets/head-shoulders.jpg';
import dove_logo from '../assets/dove.avif';
import ProductItemCar from '../components/ProductItemCar';
import CircularIndeterminate from '../components/CircularLoad';
import Cookies from 'js-cookie';
import SimpleAlert from '../components/SimpleAlert';
import BrandComponent from '../components/BrandComponent';
import SessionAlert from '../components/SessionAlert';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DiscountIcon from '@mui/icons-material/Discount';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CategoryIcon from '@mui/icons-material/Category';
import ApartmentIcon from '@mui/icons-material/Apartment';
// import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Home({message, type, alertDisplay}){
    const [data, setData] = useState([]);
    const [discountProducts, setDiscountProducts] = useState([]);
    const [loader, setLoader ] = useState(true)
    const [language, setLanguage] = useState('english');
    
    const translations = {
        english: {
            category1: 'Pain killers',
            category2: 'Vitamins',
            category3: 'Cold/cough',
            category4: 'Body care',
            category5: 'Hygiene',
            category6: 'First aid',
        },
        arabic: {
            category1: 'مسكنات الألم',
            category2: 'الفيتامينات',
            category3: 'سعال البرد',
            category4: 'العناية بالجسم',
            category5: 'صحة',
            category6: 'إسعافات أولية',
        },
    };

    useEffect(() => {
        if (Cookies.get('language')) {
            setLanguage(Cookies.get('language'));
        } else {
            Cookies.set('language', 'english');
            setLanguage('english');
        }
        fetchAllProducts();
        fetchDiscountedProducts();
    }, []);
    
    const fetchAllProducts = async () => {
        try {
          const response = await fetch('/api/product/all');
          if (response.ok) {
            const products = await response.json();
            setLoader(false)
            setData(products);
          } else {
            console.error('Failed to fetch products');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const fetchDiscountedProducts = async () => {
        try {
          const response = await fetch('/api/product/discounted-products-home');
          if (response.ok) {
            const productsWithDiscount = await response.json();
            // setLoader(false)
            setDiscountProducts(productsWithDiscount);
          } else {
            console.error('Failed to fetch discount products');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const listRef = useRef(null); // Gives access to the actual DOM element (the item-list div)
    const [scrollLeft, setScrollLeft] = useState(0); // Ensures that the component stays updated with the current scroll position
    const itemWidth = 500; // move left or right 500px
    const padding = 10;  // extra spacing between items

    // When u click left or right , the carousel shifts by 500 + 10 = 510px
    const scrollLeftHandler = () => {
        listRef.current.scrollLeft -= itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };

    const scrollRightHandler = () => {
        listRef.current.scrollLeft += itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };
      
    return (
        <div className='home-div' dir={language === 'arabic' ? 'rtl' : 'ltr'} lang={language === 'arabic' ? 'ar' : 'en'}>
            <SimpleAlert key="alert" message={message} type={type} display={alertDisplay}/>
            <SessionAlert/>
            
            <section id='carousel-bigcontainer'>
                <h2 key="products-title" id='productshome-h2'>
                    {language === 'arabic' ? 'منتجات' : 'Products'} <ShoppingBasketIcon style={{color: 'green'}}/>
                </h2>
                <div key="carousel-car" className="container-car" style={{marginTop: '3%'}}>
                    <button key="prev-btn" id="prev-btn" className="prev-btn" onClick={scrollLeftHandler}>
                        <ArrowLeftIcon id="left-arrow" style={{ fontSize: '30px' }}/>
                    </button>

                    <div key="product-list" id="item-list" className="item-list" ref={listRef} style={{ scrollLeft: scrollLeft, alignItems: loader ? 'center' : '', justifyContent: loader ? 'center' : '' }}>
                       <CircularIndeterminate display={loader} />
                            {data.map(product => (
                                <ProductItemCar key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language}/>
                            ))}
                    </div>
                    
                    <button key="next-btn" id="next-btn" className="next-btn" onClick={scrollRightHandler}>
                        <ArrowRightIcon id="right-arrow" style={{ fontSize: '30px' }}/>
                    </button>
                </div>
            </section>

            <section key="categories-container" id="categories-container">
                <h2 key="categories-title" id='categorieshome-h2'>
                    {language === 'arabic' ? 'فئات': 'Categories'} <CategoryIcon style={{color: 'green'}}/>
                </h2>
                <div id='categories-boxes'>
                    <Category key="pain-killers" imgSource={panadol} category={"pain_killers"} label={translations[language].category1}/>
                    <Category key="vitamins" imgSource={vitamins} category={"vitamins"} label={translations[language].category2}/>
                    <Category key="cold-cough" imgSource={colds} category={"colds"} label={translations[language].category3}/>
                    <Category key="body-care" imgSource={body_care} category={"body_care"} label={translations[language].category4}/>
                    <Category key="hygiene" imgSource={hygiene} category={"hygiene"} label={translations[language].category5}/>
                    <Category key="first-aid" imgSource={first_aid} category={"first_aid"} label={translations[language].category6}/>
                </div>
            </section>

            {discountProducts.length !== 0 ? 
            <section id='carousel-bigcontainer'>
                <div className="header-container">
                    <h2 key="discountproducts-title" id='discountproductshome-h2'>
                        {language === 'arabic' ? 'منتجات' : 'Discounts'} <DiscountIcon style={{ color: 'green' }} />
                    </h2>
                    <a href='/product/discounts' style={{textDecoration: 'none'}}>
                        <button>Show all</button>
                    </a>
                </div>
                <div key="carousel-container" className="container-car">
                    <button key="prev-btn" id="prev-btn" className="prev-btn" onClick={scrollLeftHandler}>
                        <ArrowLeftIcon id="left-arrow" style={{ fontSize: '30px' }}/>
                    </button>
                    <div key="discount-list" id="item-list" className="item-list" style={{ scrollLeft: scrollLeft }}>
                        <CircularIndeterminate key="loading-spinner" display={loader} />
                        {discountProducts.map(product => (
                            <ProductItemCar key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language} />
                        ))}
                    </div>
                    <button key="next-btn" id="next-btn" className="next-btn" onClick={scrollRightHandler}>
                        <ArrowRightIcon id="right-arrow" style={{ fontSize: '30px' }}/>
                    </button>
                </div>
            </section>
            : <></>}
            
            <section key="brands-container" id="brands-container">
                <h2 key="brands-title" id='brandshome-h2'>
                    {language === 'arabic' ? 'فئات': 'Brands'} <ApartmentIcon style={{color: 'green'}}/>
                </h2>
                <div id='brands-boxes'>
                    <BrandComponent imgSource={advil} brand="advil"/>
                    <BrandComponent imgSource={centrum_logo} brand="centrum"/>
                    <BrandComponent imgSource={panadol_logo} brand="panadol"/>
                    <BrandComponent imgSource={head_shoulders} brand="head and shoulders"/>
                    <BrandComponent imgSource={dove_logo} brand="dove"/>
                    <BrandComponent imgSource={vichy_logo} brand="vichy" padding='10px'/>
                </div>
            </section>
        </div>
    )
}