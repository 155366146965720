import '../css/Navbar.css'
import * as React from 'react';
import { useEffect } from "react";
import { logout } from "../utils/logout";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Badge from '@mui/material/Badge';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Menu from '@mui/material/Menu';
import locationIcon from '../assets/location.jpg';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import useAuthStatus from '../useAuthStatus';
import { useCart } from '../CartContext';
import { useWishlist } from '../WishlistContext';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';

export default function Navbar() {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [language, setLanguage] = React.useState('english');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchResults, setSearchResults] = React.useState([]);
    const { user, authenticated, loading } = useAuthStatus();
    const { cartLength, setCartLength } = useCart();
    const { wishlistLength, setWishlistLength } = useWishlist();
    const navigate = useNavigate();

    const pages = [(authenticated && user.admin) ? 'Admin' : ''];
    const arabicToEnglish = {
        'الحساب': 'account',
        'العناوين': 'addresses',
        'الطلبات المسبقة': 'order-history',
        'تسجيل الدخول': 'login',
        'اشتراك': 'signup'
    };
    const arabicPages = ['الحساب', 'العناوين', 'الطلبات المسبقة'];
    const englishPages = [ 'Account', 'Addresses', 'Order history'];
    const englishLoginPages = ['Login', 'Signup'];
    const arabicLoginPages = ['تسجيل الدخول', 'اشتراك'];
    const settings = authenticated ? (language === 'english' ? englishPages : arabicPages) : (language === 'english' ? englishLoginPages : arabicLoginPages);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          handleSubmitSearch();
          setSearchTerm('');
          setSearchResults([]);
        }
      };
    
      const handleLogout = () => {
        logout();
      };
    
      const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
      };
    
      const handleCloseUserMenu = () => {
        setAnchorElUser(null);
      };

      const handleSearchChange = async (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (value.length === 0) {
          setSearchResults([]);
          return;
        }
        if (value.length >= 2) {
          try {
            const response = await fetch(`/api/autocomplete?name=${value}`);
            if (response.ok) {
              const data = await response.json();
              setSearchResults(data);
            } else {
              console.error('Failed to fetch autocomplete results');
            }
          } catch (error) {
            console.error('Error fetching autocomplete results:', error);
          }
        } else {
          setSearchResults([]);
        }
      };
    
      const handleSubmitSearch = async () => {
          const value = searchTerm; // Directly use the state value
          if(value.length === 0) {
            return;
          }
          try {
            const response = await fetch(`/api/search?name=${value}`);
            if (response.ok) {
              const data = await response.json();
              // send data as prop and redirect to search results page
              setSearchResults([]);
              navigate('/search-results', { state: { searchData: data, value } });
            } else {
              console.error('Failed to fetch results');
            }
          } catch (error) {
            console.error('Error fetching results:', error);
          }
      };

      function handleAddressChange(event) {
        var selectedAddress = event.target.value;
        var address = { address: selectedAddress };
    
        fetch('/api/set-address', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(address)
        })
        .then(function(response) {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        })
        .catch(function(error) {
            console.error('Error changing address:', error);
        });
      }

      useEffect(() => {
        if (Cookies.get('language')) {
          setLanguage(Cookies.get('language'));
        } else {
          Cookies.set('language', 'english');
          setLanguage('english');
        }
        fetchCartLength();
        if(user) {
          setWishlistLength(user.wishlist.length)
        }
    }, [user]);
  
    const fetchCartLength = async () => {
        if (!user) {
            try {
                const response = await fetch('/api/user/cart');
                if (response.ok) {
                    const results = await response.json();
                    setCartLength(results.cart.length);
                } else {
                    console.error('Failed to fetch cart');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            setCartLength(user.cart.length);
        }
    };
  
    // const changeLanguage = () => {
    //     Cookies.set('language', language === 'english' ? 'arabic' : 'english');
    //     setLanguage(language === 'english' ? 'arabic' : 'english');
    //     window.location.reload();
    // }

    
    if (loading) {
        return null;
    }

    return (
        <>
        <nav className='test2-nav'>
            <div className='left-side'>
                <Link key="home-link" to="/" style={{ color: 'green' }}>
                    <div className='pharmacy-div' style={{color: 'white'}}>
                        <span style={{fontSize: '20px'}}><LocalHospitalIcon/></span>
                        <span style={{marginLeft: '10px', fontSize: '20px', fontWeight: 'bold'}}>pharmacy</span>
                    </div>
                </Link>
                <div className='delivery-div'>
                    <p style={{ fontSize: '13px', margin: 0}}>
                    {authenticated && (
                            <span>Delivery to</span>
                        )}
                        {authenticated && (
                            <select
                            key="location-select"
                            className="addressSelect"
                            id="addressSelect2"
                            onChange={handleAddressChange}
                            name="addChosen"
                            style={{ border: '0px', marginLeft: '5px', fontWeight: 'bold', color: 'yellow' }}
                        >
                            {user && user.addresses.length === 0 ? (
                                <option key="no-address" value={-1}>
                                    No addresses
                                </option>
                            ) : (
                                <> 
                                    <option key="address-selected" defaultValue={user && user.favorite_address}>
                                        {user && user.favorite_address >-1 && user.addresses[user.favorite_address].street}
                                    </option>
                                    {user && user.addresses.map((address, index) => (
                                        address._id !== user.addresses[user.favorite_address]._id && <option key={address.street} value={index}>{address.street}</option>
                                    ))}
                                </>
                            )}
                        </select>
                        )}
                    </p>
                </div>
            </div>
            <div className='middle-side'>
                <div class="search-bar">
                    <div class="search-input">
                        <input 
                            placeholder='Search Pharmacy'
                            onChange={handleSearchChange}
                            value={searchTerm}
                            key="search-input"
                            required
                            type='search'
                            style={{paddingRight: '10px'}}
                        >
                        </input>
                    </div>
                    <ul id="specs">
                        {searchResults.map((result, index) => (
                            <a key={result._id} href={`/product/${result._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                                <li style={{ fontWeight: '500', backgroundColor: '#fafaf2', padding: '5px' }}>
                                    <img 
                                        alt='product' 
                                        src={result.images[0].url} 
                                        height='50px' 
                                        width='50px' 
                                        style={{ marginRight: '10px' }} 
                                    />
                                    {result.english_name}
                                </li>
                            </a>
                        ))}
                    </ul>
                    <div class="button-div">
                        <button
                            type="button"
                            onClick={handleSubmitSearch}
                        >
                            <i key="search-icon" style={{fontSize: '20px'}} className="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className='right-side'>
                {!authenticated ? (
                <>
                <Link key="signin-mobile" to="/user/signup" style={{textDecoration: 'none'}}>
                    <div className='signin-div'>
                        <span style={{fontSize: '18px', fontWeight: 'bold'}}>sign up</span>
                        {/* <span style={{fontWeight: 'bold', fontSize: '14px', textDecoration: 'none'}}>or create account</span> */}
                    </div>
                </Link>
                <Link key="signin-mobile" to="/user/login" style={{textDecoration: 'none'}}>
                    <div className='signin-div'>
                        <span style={{fontSize: '18px', fontWeight: 'bold'}}>sign in</span>
                        {/* <span style={{fontWeight: 'bold', fontSize: '14px', textDecoration: 'none'}}>or create account</span> */}
                    </div>
                </Link>
                </>
                ) : null}
                <div className='cart-wish-div'>
                    <Link key="cart-link-mobile" to="/user/cart">
                        <IconButton style={{ color: 'white' }} key="cart-icon" size="large" aria-label={`show ${cartLength} items`} color="inherit">
                            <Badge badgeContent={cartLength} color="error">
                                <ShoppingCartIcon style={{ fontSize: '25px' }} />
                            </Badge>
                        </IconButton>
                    </Link>
                    {authenticated ? (
                    <Link key="wishlist-link-mobile" to="/user/wishlist">
                        <IconButton style={{ color: 'white' }} key="fav-icon" aria-label={`show ${user && wishlistLength} items`} color="inherit">
                            <Badge badgeContent={user ? wishlistLength : 0} color="error">
                                <FavoriteIcon style={{ fontSize: '25px' }} />
                            </Badge>
                        </IconButton>
                    </Link>
                    ) : null}
                </div>
                {authenticated ? (
                <div className='account-div'>
                    <Tooltip key="tooltip" title="Open settings">
                        <IconButton key="icon-button" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar key="avatar2" sx={{ bgcolor: "green" }}>
                                <AccountCircleIcon key="account-circle-icon" style={{ fontSize: '25px' }} />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        key="menu"
                        sx={{ mt: '45px', }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                    {settings.map((setting) => (
                        <Link key={`setting-${setting}`} to={language === 'english' ? (`/user/${setting.toLowerCase().replace(/\s+/g, '-')}`) : (`/user/${arabicToEnglish[setting]}`)} style={{ color: 'inherit', textDecoration: 'none' }}>
                        <MenuItem key={`menu-item-${setting}`} onClick={handleCloseUserMenu} style={{ width: '180px' }}>
                            <Typography
                            key={`menu-item-typography-${setting}`}
                            textAlign="center"
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                            >
                            <span>{setting}</span>
                            <span>
                                {setting === 'Account' && <AccountCircleIcon key={`account-icon-${setting}`} style={{ color: 'green' }} />}
                                {setting === 'Addresses' && <HomeIcon key={`home-icon-${setting}`} style={{ color: 'green' }} />}
                                {setting === 'Order history' && <ReceiptLongIcon key={`receipt-icon-${setting}`} style={{ color: 'green' }} />}
                                {setting === 'Login' && <LoginIcon key={`login-icon-${setting}`} style={{ color: 'green' }} />}
                                {setting === 'Signup' && <MailOutlineIcon key={`mail-icon-${setting}`} style={{ color: 'green' }} />}
                            </span>
                            </Typography>
                        </MenuItem>
                        <hr key={`menu-item-divider-${setting}`} style={{ margin: '0px', color: 'grey', display: setting === 'Signup' ? 'none' : 'block' }}></hr>
                        </Link>
                    ))}
                    {authenticated ? (
                        <div>
                            <Link key='user-card' to='/user/card' style={{ color: 'inherit', textDecoration: 'none' }}>
                                <MenuItem key="credit-card-menu-item" onClick={handleCloseUserMenu} style={{ width: '180px' }}>
                                <Typography
                                    key="credit-card-typography"
                                    textAlign="center"
                                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                                >
                                    <span>Credit card</span>
                                    <span><CreditCardIcon key="credit-card-icon" style={{ color: 'green' }} /></span>
                                </Typography>
                                </MenuItem>
                                <hr key="credit-card-divider" style={{ margin: '0px', color: 'grey' }}></hr>
                            </Link>
                            <div key="logout-div" onClick={handleLogout}>
                                <MenuItem key="logout-menu-item" onClick={handleCloseUserMenu} style={{ width: '180px' }}>
                                <Typography
                                    key="logout-typography"
                                    textAlign="center"
                                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                                >
                                    <span>{language === 'english' ? 'Logout' : 'تسجيل خروج'}</span>
                                    <span><LogoutIcon key="logout-icon" style={{ color: 'green' }} /></span>
                                </Typography>
                            </MenuItem>
                        </div>
                    </div>
                    ) : null}
                    </Menu>
                </div>
                ) : null}
            </div>
        </nav>

        <nav className='mobile2-nav'>
            <div class="search-bar">
                <div class="search-input">
                    <input 
                        placeholder='Search Pharmacy'
                        onChange={handleSearchChange}
                        value={searchTerm}
                        key="search-input"
                        required
                        type='search'
                    >
                    </input>
                </div>
            <ul key="specs" id="specs" style={{listStyleType: 'none', margin: '0', padding: '0', position: 'absolute', width: '95%', zIndex: '1'}}>
                {searchResults.map((result, index) => (
                    <a href={`/product/${result._id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                    <li key={index} style={{fontWeight: '500', backgroundColor: '#fafaf2', padding: '5px', fontSize: '15px'}}>
                        <img alt='product' src={result.images[0].url} height='50px' width='50px' style={{marginRight: '10px'}}></img> {result.english_name}
                    </li>
                    </a>
                ))}
            </ul>
                <div class="button-div">
                    <button
                        type="search"
                        onClick={handleSubmitSearch}
                    >
                        <i key="search-icon" style={{fontSize: '18px', color: 'white'}} className="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </nav>

        {authenticated &&         
        <nav className='mobile3-nav'>
            <div key="location-mobile" className="location-mobile">
                <img key="location-img" alt='locationicon' src={locationIcon} style={{marginRight: '5px'}} width="22" height="22" />
                <span key="location-paragraph">
                    Delivery to
                    <select
                        key="location-select"
                        className="addressSelect"
                        id="addressSelect2"
                        onChange={handleAddressChange}
                        name="addChosen"
                        style={{ color: '#20b2aa', border: '0px', marginLeft: '5px' }}
                    >
                        {user && user.addresses.length === 0 ? (
                            <option key="no-address" defaultValue={-1}>
                                No addresses
                            </option>
                        ) : (
                            <> 
                            <option key="address-selected" defaultValue={user && user.favorite_address}>
                                {user && user.favorite_address >-1 && user.addresses[user.favorite_address].street}
                            </option>
                            {user && user.addresses.map((address, index) => (
                                address._id !== user.addresses[user.favorite_address]._id && <option key={address.street} value={index}>{address.street}</option>
                            ))}
                            </>
                        )}
                    </select>
                </span>
            </div>
        </nav>
        }
        </>
    )
}