import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SimpleAlert from '../components/SimpleAlert';
import { useForm } from "react-hook-form";
import useCsrfToken from '../useCsrfToken';

const defaultTheme = createTheme();

export default function EditProduct() {
    const [product, setProduct] = useState(null);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('success');
    const [alertDisplay, setAlertDisplay] = useState('none');
    const [alertKey, setAlertKey] = useState(0);
    const [checked, setChecked] = useState(true);
    const csrfToken = useCsrfToken();
    const { id } = useParams();

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        fetchProduct();
    }, []);

    const fetchProduct = async () => {
        try {
          const response = await fetch(`/api/product/${id}`);
          if (response.ok) {
            const data = await response.json();
            setProduct(data.product);
            setChecked(data.product.in_stock);
          } else {
            console.error('Failed to fetch product');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const handleEditProduct = async (formData) => {
        setLoader(true);
        const { english_name, arabic_name, price, brand, code, arabic_description, english_description, category, discount, nb_in_stock } = formData;
        const productData = {
            product: {
                english_name,
                arabic_name,
                english_description,
                arabic_description,
                category,
                brand,
                code,
                price,
                in_stock: checked,
                discount,
                nb_in_stock
                //store: id
            }
        };
        try {
            const response = await fetch(`/api/product/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    'CSRF-Token': csrfToken
                },
                body: JSON.stringify(productData)
            });
            if (response.ok) {
                setMessage('Product updated');
                setType('success');
                setAlertKey(prevKey => prevKey + 1);
                setAlertDisplay('block');
            } else {
                console.error("Failed");
                setMessage('Error updating product');
                setType('error');
                setAlertKey(prevKey => prevKey + 1);
                setAlertDisplay('block');
            }
        } catch (error) {
            console.error("Error:", error);
            setMessage('Error updating product');
            setType('error');
            setAlertKey(prevKey => prevKey + 1);
            setAlertDisplay('block');
        }
        setLoader(false);
    }

    if(product === null) {
        return (
            <div id='newproduct-container' style={{marginTop: '5%'}}>
                <ThemeProvider key="theme-provider" theme={defaultTheme}>
                    <Container key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                    <Box
                        key="box"
                        sx={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                            <Avatar key="avatar" sx={{ m: 1, bgcolor: 'green' }}>
                                <EditIcon />
                            </Avatar>
                            <Typography key="sign-up-title" component="h1" variant="h5">
                                Edit product
                            </Typography>
                            <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                <CircularIndeterminate key="loading-spinner" display={true}/>
                            </div>
                        </Box>
                    </Container>
                </ThemeProvider>
             </div>
         )
     }

    return (
        <div id='newproduct-container' style={{marginTop: '5%'}}>
            <SimpleAlert key={alertKey} message={message} type={type} display={alertDisplay}/>
            <ThemeProvider theme={defaultTheme}>
              <Container component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                  <CssBaseline />
                    <Box
                    sx={{
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Avatar sx={{ m: 1, bgcolor: 'green' }}>
                        <EditIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Edit product
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit(handleEditProduct)} sx={{ mt: 3 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="english_name"
                            label="English name"
                            name="english_name"
                            defaultValue={product.english_name}
                            {...register("english_name", { required: "English name cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.english_name && errors.english_name.message}
                          </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="arabic_name"
                            label="Arabic name"
                            name="arabic_name"
                            defaultValue={product.arabic_name}
                            {...register("arabic_name", { required: "Arabic name cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.arabic_name && errors.arabic_name.message}
                          </small>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="english_description"
                            label="English Description"
                            name="english_description"
                            defaultValue={product.english_description}
                            {...register("english_description", { required: "English description cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.english_description && errors.english_description.message}
                          </small>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            fullWidth
                            id="arabic_description"
                            label="Arabic Description"
                            name="arabic_description"
                            defaultValue={product.arabic_description}
                            {...register("arabic_description", { required: "Arabic description cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.arabic_description && errors.arabic_description.message}
                          </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="brand-product"
                            label="Brand"
                            name="brand"
                            defaultValue={product.brand}
                            {...register("brand", { required: "Brand cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.brand && errors.brand.message}
                          </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="price"
                            required
                            fullWidth
                            id="price"
                            label="Price"
                            defaultValue={product.price}
                            {...register("price", { required: "Price cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.price && errors.price.message}
                          </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="category"
                            label="Category"
                            name="category"
                            defaultValue={product.category}
                            {...register("category", { required: "Category cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.category && errors.category.message}
                          </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="nb_in_stock"
                            label="Number in stock"
                            name="nb_in_stock"
                            defaultValue={product.nb_in_stock}
                            {...register("nb_in_stock", { required: "Nb in stock cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.nb_in_stock && errors.nb_in_stock.message}
                          </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="code"
                            label="Code"
                            name="code"
                            defaultValue={product.code}
                            {...register("code", { required: "Code cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.code && errors.code.message}
                          </small>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            fullWidth
                            id="discount"
                            label="Discount (in %)"
                            name="discount"
                            defaultValue={product.discount}
                            {...register("discount", { required: "Discount cannot be blank" })}
                          />
                          <small className="text-danger">
                            {errors?.discount && errors.discount.message}
                          </small>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="file"
                                id="image"
                                name="image"
                                inputProps={{ multiple: true }}
                                {...register("image")}
                            />
                            <small className="text-danger">
                                {errors?.image && errors.image.message}
                            </small>
                        </Grid> */}
                      </Grid>
                      <FormGroup style={{marginTop: '10px'}}>
                        <FormControlLabel 
                            control={
                            <Switch
                                style={{color: 'green'}}
                                checked={checked} 
                                onChange={handleSwitchChange} 
                                inputProps={{ 'aria-label': 'controlled' }}/>
                            } 
                            label="In stock"/>
                      </FormGroup>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, bgcolor: "green" }}
                      >
                        Save
                      </Button>
                      <Grid container justifyContent="flex-end"></Grid>
                    </Box>
                    <div style={{marginTop: '30px', marginBottom: '20px'}}>
                        <CircularIndeterminate key="loading-spinner" display={loader}/>
                    </div>
                  </Box>
              </Container>
            </ThemeProvider>
          </div>
      );
  }