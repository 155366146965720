import React, { useEffect, useRef } from "react";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css"; // Import Dropzone's CSS for styling

export default function Test2() {
    // const formRef = useRef(null); // Reference to the form element

    // useEffect(() => {
    //     // Initialize Dropzone on the form element only if it hasn't been initialized
    //     const myDropzone = new Dropzone(formRef.current, {
    //         url: "/file-upload", // Replace with your endpoint
    //         autoProcessQueue: false, // Set to true if you want it to auto-upload
    //         addRemoveLinks: true, // Optional, adds links to remove files
    //         acceptedFiles: "image/*", // Accept only images
    //         maxFiles: 10, // Limit to 10 files
    //     });

    //     // Handle events
    //     myDropzone.on("addedfile", (file) => {
    //         console.log(`File added: ${file.name}`);
    //     });

    //     return () => {
    //         myDropzone.destroy(); // Clean up Dropzone instance on unmount
    //     };
    // }, []);

    return (
        <></>
    );
}