import './index.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CartProvider } from './CartContext';
import { WishlistProvider } from './WishlistContext';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
// import Test from "./pages/Test";
import Contact from "./pages/Contact";
import reportWebVitals from './reportWebVitals';
import CategoryPage from "./pages/CategoryPage";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import CheckoutPage from "./pages/CheckoutPage";
import Cart from "./pages/Cart";
import Wishlist from "./pages/Wishlist";
import NewProduct from "./pages/NewProduct";
import CreditCard from "./pages/CreditCard";
import CircularIndeterminate from './components/CircularLoad';
import Test2 from "./pages/Test2";
import Product from "./pages/Product";
import Account from "./pages/Account";
import EditProduct from "./pages/EditProduct";
import ShowOrder from "./pages/ShowOrder";
import OrderHistory from "./pages/OrderHistory";
import Brand from "./pages/Brand";
import VerifyEmail from "./pages/VerifyEmail";
import ResetPassword from "./pages/ResetPassword";
import Addresses from "./pages/Addresses";
import AddAddress from "./pages/AddAddress";
import AddStore from "./pages/AddStore";
import UpdateAddress from "./pages/UpdateAddress";
import SearchResults from "./pages/SearchResults";
import DiscountsPage from "./pages/DiscountsPage";
import NavbarTest from "./tests/NavbarTest";
import Carousel from "./tests/Carousel";
import Stores from "./pages/Stores";
import ReactGA from "react-ga4";
import useAuthStatus from './useAuthStatus';

const TRACKING_ID = "G-Y6NYNWRLY8";
ReactGA.initialize(TRACKING_ID);

export default function App() {
  const { user, authenticated } = useAuthStatus();

  if (authenticated === false && user === null) {
    return (
      <div id="loading-container">
          <CircularIndeterminate display={true}/>
      </div>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          {/* USER */}
          <Route path="/user/checkout" element={authenticated ? (user.cart.length > 0 ? <CheckoutPage /> : <Cart message="You cart is empty" type='info' alertDisplay='block'/>) : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>} />
          <Route path="/user/cart" element={<Cart/>} />
          <Route path="/user/wishlist" element={authenticated ? <Wishlist /> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>}/>
          <Route path="/user/addresses" element={authenticated ? <Addresses /> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>}/>
          <Route path="/user/add-address" element={authenticated ? <AddAddress /> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>}/>
          <Route path="/user/address/:index" element={authenticated ? <UpdateAddress /> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>}/>
          <Route path="/user/login" element={authenticated ? <Home message={'Already logged in'} type={'info'} alertDisplay={'block'}/> : <SignIn />}/>
          <Route path="/user/signup" element={authenticated ? <Home message={'Already logged in'} type={'info'} alertDisplay={'block'}/> : <SignUp />} />
          <Route path="/user/account" element={authenticated ? <Account/> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>} />
          <Route path="/user/card" element={authenticated ? <CreditCard/> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>} />
          <Route path="/user/order-history" element={authenticated ? <OrderHistory/> : <SignIn message={'You must be logged in'} type={'info'} alertDisplay={'block'}/>} />
          <Route path="/user/stores" element={<Stores />} />

          {/* PRODUCT */}
          <Route path="/product/category/:category" element={<CategoryPage />} />
          <Route path="/product/discounts/" element={<DiscountsPage />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/product/edit/:id" element={(authenticated && user.admin) ? <EditProduct /> : <Home message={'Forbidden'} type={'error'} alertDisplay={'block'}/>} />

          {/* STORE */}
          <Route path="/addstore" element={<AddStore />} />

          {/* ADMIN */}
          <Route path="/admin/product/new" element={(authenticated ) ? <NewProduct /> : <Home message={'Forbidden'} type={'error'} alertDisplay={'block'}/>} />

          {/* ORDER */}
          <Route path="/order/:orderNb" element={<ShowOrder />} />

          {/* OTHER */}
          <Route path="/search-results" element={<SearchResults/>}/>
          <Route path="/contact" element={<Contact />} />
          <Route path="/brand/:brand" element={<Brand />} />
          <Route path="*" element={<Home message={'Page not found'} type={'info'} alertDisplay={'block'}/>} />
        </Route>
        <Route path="/verify-email/:email/:token" element={<VerifyEmail/>}/>
        <Route path="/resetpassword/:userID/:token" element={<ResetPassword/>}/>
        <Route path="/testnav" element={<NavbarTest/>}/>
        {/* <Route path="/testnav2" element={<NavbarTest2/>}/> */}
        <Route path="/testcar" element={<Carousel/>}/>
        {/* <Route path="/test" element={<Test />} /> */}
        <Route path="/test2" element={<Test2 />} />
      </Routes>
    </BrowserRouter>
  );
}

// Function to send web vitals to Google Analytics
const sendToGoogleAnalytics = ({ name, delta, id }) => {
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    label: id,
    value: Math.round(name === 'CLS' ? delta * 1000 : delta), // values must be integers
    nonInteraction: true, // avoids affecting bounce rate
  });
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CartProvider>
    <WishlistProvider>
      <App />
    </WishlistProvider>
  </CartProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToGoogleAnalytics);