import './NavbarTest.css'
import amazon from '../assets/amazon.PNG';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function NavbarTest() {
    return (
        <>
        <nav className='test-nav'>
            <div className='left-side'>
                <div className='amazon-div'>
                    <img src={amazon} className='amazon-logo'></img>
                </div>
                <div className='delivery-div'>
                    <span style={{fontSize: '12px', color: '#e3e2dc'}}>Deliver to</span>
                    <span style={{fontWeight: 'bold', fontSize: '14px'}}><LocationOnIcon/> Lebanon</span>
                </div>
            </div>
            <div className='middle-side'>
                <select className='middle-select'>
                    <option value="volvo">All</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                </select>
                <input placeholder='Search amazon'></input>
                <button><i key="search-icon" style={{fontSize: '20px'}} className="fa fa-search"></i></button>
            </div>
            <div className='right-side'>
                <div className='right-select-div'>
                    <select className='right-select'>
                        <option value="volvo">EN</option>
                        <option value="saab">Saab</option>
                        <option value="mercedes">Mercedes</option>
                        <option value="audi">Audi</option>
                    </select>
                </div>
                <div className='signin-div'>
                    <span style={{fontSize: '12px'}}>Hello, sign in</span>
                    <span style={{fontWeight: 'bold', fontSize: '14px'}}>Accounts & Lists</span>
                </div>
                <div className='returns-div'>
                    <span style={{fontSize: '12px'}}>Returns</span>
                    <span style={{fontWeight: 'bold', fontSize: '14px'}}>& Orders</span>
                </div>
                <div className='cart-div'>
                    <span style={{fontWeight: 'bold', fontSize: '14px'}}><ShoppingCartIcon/> Cart</span>
                </div>
            </div>
        </nav>

        <nav className='mobile-nav'>
            <div className='middle-side'>
                <input placeholder='Search amazon'></input>
                <button><i key="search-icon" style={{fontSize: '20px'}} className="fa fa-search"></i></button>
            </div>
        </nav>
        </>
    )
}