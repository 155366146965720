import './Carousel.css'
import { useEffect, useState, useRef } from "react";
import Category from "../components/Category"
import panadol from '../assets/panadol-extra.avif';
import vitamins from '../assets/centrum.jpg';
import colds from '../assets/cold-cough.jpg';
import body_care from '../assets/dove.avif';
import hygiene from '../assets/head_shoulders.jpg';
import first_aid from '../assets/first_aid.jpg';
import vichy_logo from '../assets/vichy_logo.png';
import advil from '../assets/Advil_logo.png';
import centrum_logo from '../assets/centrum_logo.jpeg';
import panadol_logo from '../assets/panadol-logo.jpeg';
import head_shoulders from '../assets/head-shoulders.jpg';
import dove_logo from '../assets/dove.avif';
import ProductItemCar from '../components/ProductItemCar';
import CircularIndeterminate from '../components/CircularLoad';
import Cookies from 'js-cookie';
import SimpleAlert from '../components/SimpleAlert';
import BrandComponent from '../components/BrandComponent';
import SessionAlert from '../components/SessionAlert';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DiscountIcon from '@mui/icons-material/Discount';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CategoryIcon from '@mui/icons-material/Category';
import ApartmentIcon from '@mui/icons-material/Apartment';
// import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Carousel () {
    const [data, setData] = useState([]);
    const [discountProducts, setDiscountProducts] = useState([]);
    const [loader, setLoader ] = useState(true)
    const [language, setLanguage] = useState('english');

    useEffect(() => {
        fetchAllProducts();
    }, []);
    
    const fetchAllProducts = async () => {
        try {
          const response = await fetch('/api/product/all');
          if (response.ok) {
            const products = await response.json();
            setLoader(false)
            setData(products);
          } else {
            console.error('Failed to fetch products');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const listRef = useRef(null); // Gives access to the actual DOM element (the item-list div)
    const [scrollLeft, setScrollLeft] = useState(0); // Ensures that the component stays updated with the current scroll position
    const itemWidth = 500; // move left or right 500px
    const padding = 10;  // extra spacing between items

    // When u click left or right , the carousel shifts by 500 + 10 = 510px
    const scrollLeftHandler = () => {
        listRef.current.scrollLeft -= itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };

    const scrollRightHandler = () => {
        listRef.current.scrollLeft += itemWidth + padding;
        setScrollLeft(listRef.current.scrollLeft);
    };

    return (
        <section id='carousel-bigcontainer'>
            <h2 key="products-title" id='productshome-h2'>
                {language === 'arabic' ? 'منتجات' : 'Products'} <ShoppingBasketIcon style={{color: 'green'}}/>
            </h2>
            <div key="carousel-car" className="container-car">
                <button key="prev-btn" id="prev-btn" className="prev-btn" onClick={scrollLeftHandler}>
                    <ArrowLeftIcon id="left-arrow" style={{ fontSize: '30px' }}/>
                </button>

                <div key="item-list" id="item-list" className="item-list" ref={listRef} style={{ scrollLeft: scrollLeft }}>
                    <CircularIndeterminate key="loading-spinner" display={loader}/>
                        {data.map(product => (
                            <ProductItemCar key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language}/>
                        ))}
                </div>
                
                <button key="next-btn" id="next-btn" className="next-btn" onClick={scrollRightHandler}>
                    <ArrowRightIcon id="right-arrow" style={{ fontSize: '30px' }}/>
                </button>
            </div>
        </section>
    )
}