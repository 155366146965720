import '../css/SearchResults.css'
import { useEffect, useState } from "react";
import ProductItem from "../components/ProductItem";
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import SessionAlert from '../components/SessionAlert'

export default function SearchResults() {
    const location = useLocation();
    const searchData = location.state?.searchData;
    const searchValue = location.state?.value;
    const [language, setLanguage] = useState('english');
    
    useEffect(() => {
        if (Cookies.get('language')) {
            setLanguage(Cookies.get('language'));
        } else {
            Cookies.set('language', 'english');
            setLanguage('english');
        }
    }, []);

    const categories = {
        'pain_killers': (language === 'arabic') ? 'مسكنات الألم' : 'Pain killers',
        'vitamins': (language === 'arabic') ? 'الفيتامينات' : 'Vitamins',
        'colds': (language === 'arabic') ? 'سعال البرد' : 'Cold/cough',
        'body_care': (language === 'arabic') ? 'العناية بالجسم' : 'Body care',
        'hygiene': (language === 'arabic') ? 'صحة' : 'Hygiene',
        'first_aid': (language === 'arabic') ? 'إسعافات أولية' : 'First aid',
    };
    
    return (
        <div id='searchpage-container'>
            <SessionAlert/>
            <h4 id='searchresults-h4'>Results for: {searchValue}</h4>
            <section id="big-search-container">
                <aside id="listAside">
                    <h4 style={{color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300'}}>Categories</h4>
                    <ul>
                        {Object.entries(categories).map(([catKey, catName]) => (
                            <li key={catKey}>
                                <a href={`/product/category/${catKey}`}>
                                    {catName}
                                </a>
                            </li>
                        ))}
                    </ul>
                    
                    <div id="brand-filter">
                        <h5 style={{color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300'}}>{'Brands'}</h5>
                    </div>
                </aside>
                <aside id="productsAside">
                    <div className="search-container">
                        {searchData.map(product => (
                            <ProductItem key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language}/>
                        ))}
                    </div>
                </aside>
            </section>
        </div>
    );
}


// import '../css/SearchResults.css'
// import { useEffect, useState } from "react";
// import ProductItem from "../components/ProductItem";
// import { useLocation } from 'react-router-dom';
// import Cookies from 'js-cookie';
// import CircularIndeterminate from '../components/CircularLoad';
// import SimpleAlert from '../components/SimpleAlert';
// import SessionAlert from '../components/SessionAlert'
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';
// import Skeleton from '@mui/material/Skeleton';
// import Box from '@mui/material/Box';
// import Slider from '@mui/material/Slider';
// import google_menu from '../assets/google_menu.svg'
// import google_close from '../assets/google_close.svg'

// export default function SearchResults() {
//     const location = useLocation();
//     const searchData = location.state?.searchData;
//     const searchValue = location.state?.value;
//     const [language, setLanguage] = useState('english');
//     const [maxPrice, setMaxPrice] = useState(0);
//     const [totalProducts, setTotalProducts] = useState(0);
//     const [loading, setLoading] = useState(true);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(0);
//     const [options, setOptions] = useState([]);
//     const [error, setError] = useState(null);
//     const [selectedBrands, setSelectedBrands] = useState([]);
//     const [priceRange, setPriceRange] = useState([0, maxPrice]); // Initial price range
//     const [isCollapsed, setIsCollapsed] = useState(true);
//     const productsPerPage = 12;
    
//     useEffect(() => {
//         if (Cookies.get('language')) {
//             setLanguage(Cookies.get('language'));
//         } else {
//             Cookies.set('language', 'english');
//             setLanguage('english');
//         }
//     }, []);

//     const handlePageChange = (event, value) => {
//         setCurrentPage(value);
//     };

//     const handleCheckboxChange = (event) => {
//         const { value } = event.target;
//         setSelectedBrands(prev =>
//             prev.includes(value) ? prev.filter(brand => brand !== value) : [...prev, value]
//         );
//     };

//     const handlePriceChange = (event, newValue) => {
//         setPriceRange(newValue); // Update the selected price range
//     };    

//     const filteredData = searchData.filter(product =>
//         (selectedBrands.length === 0 || selectedBrands.includes(product.brand)) &&
//         product.price >= priceRange[0] && product.price <= priceRange[1]
//     );    

//     function getMax(product) {
//         if(product.price > maxPrice) {
//             setMaxPrice(product.price)
//             console.log(product.price)
//             console.log('maxprice', maxPrice)
//         }
//     }

//     const categories = {
//         'pain_killers': (language === 'arabic') ? 'مسكنات الألم' : 'Pain killers',
//         'vitamins': (language === 'arabic') ? 'الفيتامينات' : 'Vitamins',
//         'colds': (language === 'arabic') ? 'سعال البرد' : 'Cold/cough',
//         'body_care': (language === 'arabic') ? 'العناية بالجسم' : 'Body care',
//         'hygiene': (language === 'arabic') ? 'صحة' : 'Hygiene',
//         'first_aid': (language === 'arabic') ? 'إسعافات أولية' : 'First aid',
//     };

//     const toggleSidebar = () => {
//         setIsCollapsed(!isCollapsed);
//     };
    
//     return (
//         <div dir={language === 'arabic' ? 'rtl' : 'ltr'} className='categorypage-container' style={{marginLeft: isCollapsed ? '' : '0', marginRight: isCollapsed ? '' : '0'}}>
//             <aside className={`side-bar ${isCollapsed ? 'collapsed' : ''}`}>
//                 <div style={{display: 'flex', marginBottom: '15px'}}>
//                     <h3 style={{color: 'green', margin: 0}}>Catgories</h3>
//                     <button onClick={toggleSidebar}>
//                         <img src={google_close}></img>
//                     </button>
//                 </div>
//                 <div className='sidebar-ul'>
//                     <ul>
//                         {Object.entries(categories).map(([catKey, catName]) => (
//                             <li key={catKey}>
//                                 <a href={`/product/category/${catKey}`}>
//                                     {catName}
//                                 </a>
//                             </li>
//                         ))}
//                     </ul>
//                     <hr />
//                     <div id="brand-filter">
//                         <h3 style={{ color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300' }}>
//                             {language === 'english' ? 'Brands' : 'الماركات'}
//                         </h3>
//                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                             <CircularIndeterminate display={loading} />
//                         </div>
//                         <FormGroup style={{ margin: '0px' }}>
//                             {options.map(option => (
//                                 <FormControlLabel
//                                     key={option.value}
//                                     color='success'
//                                     style={{ margin: '0px' }}
//                                     control={
//                                         <Checkbox
//                                             checked={selectedBrands.includes(option.value)}
//                                             onChange={handleCheckboxChange}
//                                             value={option.value}
//                                         />
//                                     }
//                                     label={option.label}
//                                 />
//                             ))}
//                         </FormGroup>
//                     </div>
//                     <hr />
//                     <div id="price-filter" style={{marginLeft: '5%', marginRight: '5%', marginTop: '2.5%'}}>
//                         <h3 style={{ color: 'green', fontWeight: '300' }}>
//                             {language === 'english' ? 'Price' : 'الماركات'}
//                         </h3>
//                         <Box>
//                             <span></span>
//                             <Slider
//                                 value={priceRange}
//                                 onChange={handlePriceChange}
//                                 valueLabelDisplay="auto"
//                                 min={0}
//                                 max={maxPrice}
//                                 step={1}
//                                 marks
//                                 sx={{ color: 'green' }}
//                             />
//                         </Box>
//                     </div>
//                 </div>
//             </aside>
//             <SessionAlert />
//             <div className='mobile-categorymenu' style={{ opacity: isCollapsed ? 1 : 0.5}}>
//                 <button onClick={toggleSidebar}>
//                     <img src={google_menu}></img>
//                 </button>
//                 <h4 id='category-h2'>{searchData}</h4>
//             </div>
//             <section className={`big-category-container ${isCollapsed ? '' : 'bigcontainer-opacity'}`} style={{ opacity: isCollapsed ? 1 : 0.5}}>
//                 <SimpleAlert id="addcart-alert" key="alert" message="Added to cart" type='success' />
//                 <aside id="listAside">
//                     <h5 style={{ color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300' }}>
//                         {language === 'arabic' ? 'فئات' : 'Categories'}
//                     </h5>
//                     <ul>
//                         {Object.entries(categories).map(([catKey, catName]) => (
//                             <li key={catKey}>
//                                 <a href={`/product/category/${catKey}`}>
//                                     {catName}
//                                 </a>
//                             </li>
//                         ))}
//                     </ul>
//                     <hr />
//                     <div id="brand-filter">
//                         <h5 style={{ color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300' }}>
//                             {language === 'english' ? 'Brands' : 'الماركات'}
//                         </h5>
//                         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                             <CircularIndeterminate display={loading} />
//                         </div>
//                         <FormGroup style={{ margin: '0px' }}>
//                             {options.map(option => (
//                                 <FormControlLabel
//                                     key={option.value}
//                                     color='success'
//                                     style={{ margin: '0px' }}
//                                     control={
//                                         <Checkbox
//                                             checked={selectedBrands.includes(option.value)}
//                                             onChange={handleCheckboxChange}
//                                             value={option.value}
//                                         />
//                                     }
//                                     label={option.label}
//                                 />
//                             ))}
//                         </FormGroup>
//                     </div>
//                     <hr></hr>
//                     <div id="price-filter" style={{marginLeft: '5%', marginRight: '5%', marginTop: '2.5%'}}>
//                         <h5 style={{ color: 'green', fontWeight: '300' }}>
//                             {language === 'english' ? 'Price' : 'الماركات'}
//                         </h5>
//                         <Box>
//                             <span></span>
//                             <Slider
//                                 value={priceRange}
//                                 onChange={handlePriceChange}
//                                 valueLabelDisplay="auto"
//                                 min={0}
//                                 max={maxPrice}
//                                 step={1}
//                                 marks
//                                 sx={{ color: 'green' }}
//                             />
//                         </Box>
//                     </div>
//                 </aside>
//                 <aside id="productsAside">
//                     <div className="category-container">
//                         {Array.from({ length: totalProducts }).map((_, index) => (
//                             <Box key={index} sx={{ pt: 0.5, display: loading ? 'block' : 'none' }}>
//                                 <Skeleton variant="rectangular" width={210} height={118} />
//                                 <Skeleton />
//                                 <Skeleton width="60%" />
//                             </Box>
//                         ))}
//                         {error ? (
//                             <div>Error: {error}</div>
//                         ) : (
//                             searchData.map(product => (
//                                 <ProductItem key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language}/>
//                             ))
//                         )}
//                     </div>
//                     <div id='category-pagination'>
//                         <Stack spacing={2}>
//                             <Pagination
//                                 count={totalPages}
//                                 page={currentPage}
//                                 onChange={handlePageChange}
//                                 variant="outlined"
//                                 color="primary"
//                             />
//                         </Stack>
//                     </div>
//                 </aside>
//             </section>
//         </div>
//     );
// }