export const getWishlistLength = async() => {
    try {
        const response = await fetch('/api/user/wishlist');
        if (response.ok) {
            const results = await response.json();
            return results.wishlist.length;
        } else {
            console.error('Failed to fetch updated wishlist length');
        }
    } catch (error) {
        console.error('Error fetching updated wishlist length:', error);
    }
}