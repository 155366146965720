import '../css/OrderHistory.css'
import { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import SessionAlert from '../components/SessionAlert';
import CircularIndeterminate from '../components/CircularLoad';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export default function OrderHistory(){
    const [orders, setOrders] = useState(null);

    useEffect(() => {
        fetchOrderHistory();
    }, []);
    
    const fetchOrderHistory = async () => {
        try {
          const response = await fetch('/api/user/order-history');
          if (response.ok) {
            const data = await response.json();
            setOrders(data);
            console.log(data)
          } else {
            console.error('Failed to fetch products');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    if (orders === null) {
        return (
            <div id="orderhistory-table" style={{ overflowX: 'auto' }}>
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    if(orders.length === 0) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                height: '50vh'
              }}>
                <div style={{padding: '30px', borderRadius: '50%', backgroundColor: '#f0ebeb'}}>
                    <ReceiptLongIcon style={{color: 'green', fontSize: '60px'}}/>
                </div>
                <h2 style={{marginTop: '10px'}}>No Orders Currently</h2>
                <a href='/'>
                    <button style={{fontWeight: 'bold', marginTop: '10px', border: '0px', borderRadius: '10px', color: 'white', backgroundColor: '#2cd451', padding: '15px 100px'}}>Start Shopping Now</button>
                </a>
            </div>
        )
    }

    return (
        <div>
            <SessionAlert/>
            <div id="orderhistory-table" style={{ overflowX: 'auto' }}>
                <Table bordered>
                    <thead>
                        <tr>
                            <th className='cart-th' style={{ borderRight: 'none', fontSize: '20px' }}>Order history</th>
                            <th style={{ borderLeft: 'none', borderRight: 'none' }}></th>
                            <th style={{ borderLeft: 'none', }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => (
                            <tr id={`order-${order._id}`} key={`order-${order._id}`}>
                                <td style={{textDecoration: 'underline'}}>
                                    <Link to={`/order/${order.number}`}>
                                        Order #{order.number}
                                    </Link>
                                </td>
                                <td>Status: {order.status}</td>
                                <td>Date ordered: {order.date.split("T")[0]}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}