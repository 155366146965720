import '../css/Category.css'
import { Link } from 'react-router-dom';

export default function Category({ imgSource, category, label }) {
    return (
        <div id="home-category">            
            <Link key="wishlist-link" to={`/product/category/${category}`}>
                <img src={imgSource} alt='category'/>
                <h4>{label}</h4>
            </Link>
        </div>
    );
}