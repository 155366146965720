import '../css/SignUp.css'
import * as React from 'react';
import { useNavigate  } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { TextField, InputAdornment } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularIndeterminate from '../components/CircularLoad';
import BasicSelect from '../components/BasicSelect';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SimpleAlert from '../components/SimpleAlert';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
         Pharmacy
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {
    const [loader, setLoader] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState('');
    const [alertType, setAlertType] = React.useState('');
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const registerOptions = {
        username: {
            required: "Username is required",
            minLength: {
                value: 3,
                message: "Username must be at least 3 characters"
            },
            maxLength: {
                value: 30,
                message: "Username cannot exceed 30 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9]+$/,
                message: "Username must be alphanumeric"
            }
        },
        full_name: {
            required: "Full name is required",
            minLength: {
                value: 3,
                message: "Full name must be at least 3 characters"
            },
            maxLength: {
                value: 30,
                message: "Full name cannot exceed 30 characters"
            },
            pattern: {
                value: /^[a-zA-Z0-9\s]+$/,
                message: "Full name should only contain letters"
            }
        },
        phone_nb: {
            required: "Phone number is required",
            pattern: {
                value: /^\d{8}$/,
                message: "Phone number must be exactly 8 digits"
            }
        },
        email: {
            required: "Email is required",
            pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net)$/,
                message: "Email must be a valid .com or .net address"
            }
        },
        password: {
            required: "Password is required",
            pattern: {
                value: /^[a-zA-Z0-9]{8,30}$/,
                message: "Password must be 8-30 characters long and alphanumeric"
            }
        },
        type: {
            required: "Type is required"
        }
    };

    const handleRegister = async (formData) => {
        setLoader(true)
        const { full_name, username, phone_nb, email, password } = formData;
        const type = 'normal';
        let data;
        try {
            const response = await fetch("/api/user/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: { full_name, username, phone_nb, email, type },
                    password
                })
            });
            data = await response.json();
            if (data.success) {
                sessionStorage.setItem('sessionMessage', 'Welcome!');
                navigate("/");
                window.location.reload();
            } else {
                setAlertMsg(data.message);
                setAlertType('error')
            }
        } catch (error) {
            setAlertMsg('An error occured');
            setAlertType('error')
        }
        setLoader(false)
    };

    const handleError = (errors) => {};

    const options = [{key: "Mr.", value: "Mr."}, {key: "Mrs.", value: "Mrs."}]

    return (
        <div id='signup-bigcontainer' style={{marginTop: '5%'}}>
            <ThemeProvider key="theme-provider" theme={defaultTheme}>
                <Container className='signup-container' key="main-container" component="main" maxWidth="xs" style={{border: '0.2px solid rgb(229, 228, 228)', borderRadius: '20px'}}>
                    <CssBaseline />
                        <Box
                            key="box"
                            sx={{
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                        <SimpleAlert message={alertMsg} type={alertType} display={alertMsg !== '' ? 'block' : 'none'}/>
                        <Avatar key="avatar" sx={{ m: 1, bgcolor: 'green' }}>
                            <MailOutlineIcon />
                        </Avatar>
                        <Typography key="sign-up-title" component="h2" variant="h5">
                            Sign up
                        </Typography>
                        <Box key="form-box" component="form" noValidate onSubmit={handleSubmit(handleRegister, handleError)} sx={{ mt: 3 }}>
                            <Grid key="grid-container" container spacing={2}>
                                <Grid key="full-name-field" item xs={12} sm={6}>
                                    <TextField
                                        key="full-name-input"
                                        autoComplete="first-name"
                                        name="full_name"
                                        required
                                        fullWidth
                                        id="full_name"
                                        label="Full Name"
                                        placeholder='Full name'
                                        {...register("full_name", registerOptions.full_name)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonIcon style={{color: 'green'}}/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <small className="text-danger">
                                        {errors?.full_name && errors.full_name.message}
                                    </small>
                                </Grid>
                                <Grid key="username-field" item xs={12} sm={6}>
                                    <TextField
                                        key="username-input"
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        placeholder='Username'
                                        name="username"
                                        autoComplete="username"
                                        {...register("username", registerOptions.username)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircleIcon style={{color: 'green'}}/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <small className="text-danger">
                                        {errors?.username && errors.username.message}
                                    </small>
                                </Grid>
                                <Grid key="email-field" item xs={12}>
                                    <TextField
                                        key="email-input"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        placeholder='Enter your email'
                                        name="email"
                                        autoComplete="email"
                                        {...register("email", registerOptions.email)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MailOutlineIcon style={{color: 'green'}}/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <small className="text-danger">
                                        {errors?.email && errors.email.message}
                                    </small>
                                </Grid>
                                <Grid key="phone-field" item xs={12}>
                                    <TextField
                                        key="phone-input"
                                        required
                                        fullWidth
                                        id="phone_nb"
                                        label="Phone nb"
                                        placeholder='Enter your phone number'
                                        name="phone_nb"
                                        {...register("phone_nb", registerOptions.phone_nb)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PhoneIcon style={{color: 'green'}}/> +961
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <small className="text-danger">
                                        {errors?.phone_nb && errors.phone_nb.message}
                                    </small>
                                </Grid>
                                <Grid key="password-field" item xs={12}>
                                    <TextField
                                        key="password-input"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        placeholder='Enter your password'
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        {...register("password", registerOptions.password)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOutlinedIcon style={{color: 'green'}}/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <small className="text-danger">
                                        {errors?.password && errors.password.message}
                                    </small>
                                </Grid>
                                <Grid key="checkbox-field" item xs={12}>
                                    <FormControlLabel
                                        key="checkbox-label"
                                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                                        label="I want to receive inspiration, marketing promotions and updates via email."
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                key="sign-up-button"
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, bgcolor: 'green' }}
                            >
                                Sign Up
                            </Button>
                            <Grid key="link-container" container justifyContent="flex-end">
                                <Grid key="login-link-item" item>
                                    <Typography component="div" variant="body2">
                                        <Link key="wishlist-link" to="/user/login" style={{ color: 'green' }}>
                                            Already have an account? Sign in
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <div style={{marginTop: '30px'}}>
                            <CircularIndeterminate key="loading-spinner" display={loader}/>
                        </div>
                    </Box>
                </Container>
                <Copyright key="copyright" sx={{ mt: 5 }} />
            </ThemeProvider>
        </div>
    );
}