import '../css/CategoryPage.css';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import ProductItem from "../components/ProductItem";
import CircularIndeterminate from '../components/CircularLoad';
import SimpleAlert from '../components/SimpleAlert';
import SessionAlert from '../components/SessionAlert'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import google_menu from '../assets/google_menu.svg'
import google_close from '../assets/google_close.svg'

export default function CategoryPage() {
    const { category } = useParams();
    const [data, setData] = useState([]);
    const [maxPrice, setMaxPrice] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [language, setLanguage] = useState('english');
    const [options, setOptions] = useState([]);
    const [error, setError] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [priceRange, setPriceRange] = useState([0, maxPrice]); // Initial price range
    const [isCollapsed, setIsCollapsed] = useState(true);
    const productsPerPage = 12;

    useEffect(() => {
        fetchInitialProducts();
        fetchTotalProducts();
    }, [category]);

    useEffect(() => {
        fetchProductsByPage(currentPage);
    }, [currentPage]);

    const fetchInitialProducts = async () => {
        try {
            const response = await fetch(`/api/product/category/${category}?page=1&limit=${productsPerPage}`);
            if (response.ok) {
                const products = await response.json();
                const uniqueBrands = [...new Set(products.map(product => product.brand))];
                const brandOptions = uniqueBrands.map(brand => ({
                    label: brand,
                    value: brand
                }));
                setData(products);
                setOptions(brandOptions);
                setMaxPrice(Math.max(...products.map(product => product.price)));
                setPriceRange([0, Math.max(...products.map(product => product.price))]);
                setLoading(false);
            } else {
                console.error('Failed to fetch products');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchTotalProducts = async () => {
        try {
            const response = await fetch(`/api/product/category-count/${category}`);
            if (response.ok) {
                const { count } = await response.json();
                setTotalProducts(count);
                setTotalPages(Math.ceil(count / productsPerPage));
            } else {
                console.error('Failed to fetch total product count');
                setError('Failed to fetch total product count.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to fetch total product count.');
        }
    };

    const fetchProductsByPage = async (page) => {
        setLoading(true);
        if(data.length > 0) {
            setData([])
        }
        try {
            const response = await fetch(`/api/product/category/${category}?page=${page}&limit=${productsPerPage}`);
            if (response.ok) {
                const products = await response.json();
                const uniqueBrands = [...new Set(products.map(product => product.brand))];
                const newOptions = uniqueBrands.map(brand => ({
                    label: brand,
                    value: brand
                }));
                setLoading(false);
                setOptions(newOptions);
                setData(products);
                data.forEach(getMax);
            } else {
                console.error('Failed to fetch products');
                setError('Failed to fetch products.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Failed to fetch products.');
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        setSelectedBrands(prev =>
            prev.includes(value) ? prev.filter(brand => brand !== value) : [...prev, value]
        );
    };

    const handlePriceChange = (event, newValue) => {
        setPriceRange(newValue); // Update the selected price range
    };

    const filteredData = data.filter(product =>
        (selectedBrands.length === 0 || selectedBrands.includes(product.brand)) &&
        product.price >= priceRange[0] && product.price <= priceRange[1]
    );    

    function getMax(product) {
        if(product.price > maxPrice) {
            setMaxPrice(product.price)
            console.log(product.price)
            console.log('maxprice', maxPrice)
        }
    }

    const categories = {
        'pain_killers': (language === 'arabic') ? 'مسكنات الألم' : 'Pain Killers',
        'vitamins': (language === 'arabic') ? 'الفيتامينات' : 'Vitamins',
        'colds': (language === 'arabic') ? 'سعال البرد' : 'Cold/Cough',
        'body_care': (language === 'arabic') ? 'العناية بالجسم' : 'Body Care',
        'hygiene': (language === 'arabic') ? 'صحة' : 'Hygiene',
        'first_aid': (language === 'arabic') ? 'إسعافات أولية' : 'First Aid',
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    function valuetext(value) {
        return `${value}°C`;
    }

    return (
        <div dir={language === 'arabic' ? 'rtl' : 'ltr'} className='categorypage-container' style={{marginLeft: isCollapsed ? '' : '0', marginRight: isCollapsed ? '' : '0'}}>
            <aside className={`side-bar ${isCollapsed ? 'collapsed' : ''}`}>
                <div style={{display: 'flex', marginBottom: '15px'}}>
                    <h3 style={{color: 'green', margin: 0}}>Catgories</h3>
                    <button onClick={toggleSidebar}>
                        <img src={google_close}></img>
                    </button>
                </div>
                <div className='sidebar-ul'>
                    <ul>
                        {Object.entries(categories).map(([catKey, catName]) => (
                            <li key={catKey}>
                                <a href={`/product/category/${catKey}`} style={{ fontWeight: category === catKey ? 'bold' : '100' }}>
                                    {catName}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <hr />
                    <div id="brand-filter">
                        <h3 style={{ color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300' }}>
                            {language === 'english' ? 'Brands' : 'الماركات'}
                        </h3>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularIndeterminate display={loading} />
                        </div>
                        <FormGroup style={{ margin: '0px' }}>
                            {options.map(option => (
                                <FormControlLabel
                                    key={option.value}
                                    color='success'
                                    style={{ margin: '0px' }}
                                    control={
                                        <Checkbox
                                            checked={selectedBrands.includes(option.value)}
                                            onChange={handleCheckboxChange}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                    </div>
                    <hr />
                    <div id="price-filter" style={{marginLeft: '5%', marginRight: '5%', marginTop: '2.5%'}}>
                        <h3 style={{ color: 'green', fontWeight: '300' }}>
                            {language === 'english' ? 'Price' : 'الماركات'}
                        </h3>
                        <Box>
                            <span></span>
                            <Slider
                                value={priceRange}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={maxPrice}
                                step={1}
                                marks
                                sx={{ color: 'green' }}
                            />
                        </Box>
                    </div>
                </div>
            </aside>
            <SessionAlert />
            <div className='mobile-categorymenu' style={{ opacity: isCollapsed ? 1 : 0.5}}>
                <button onClick={toggleSidebar}>
                    <img src={google_menu}></img>
                </button>
                <h2 id='category-h2'>{categories[category]}</h2>
            </div>
            <section className={`big-category-container ${isCollapsed ? '' : 'bigcontainer-opacity'}`} style={{ opacity: isCollapsed ? 1 : 0.5}}>
                <SimpleAlert id="addcart-alert" key="alert" message="Added to cart" type='success' />
                <aside id="listAside">
                    <h5 style={{ color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300' }}>
                        {language === 'arabic' ? 'فئات' : 'Categories'}
                    </h5>
                    <ul>
                        {Object.entries(categories).map(([catKey, catName]) => (
                            <li key={catKey}>
                                <a href={`/product/category/${catKey}`} style={{ fontWeight: category === catKey ? 'bold' : '100' }}>
                                    {catName}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <hr />
                    <div id="brand-filter">
                        <h5 style={{ color: 'green', marginLeft: '2.5%', marginTop: '2.5%', fontWeight: '300' }}>
                            {language === 'english' ? 'Brands' : 'الماركات'}
                        </h5>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularIndeterminate display={loading} />
                        </div>
                        <FormGroup style={{ margin: '0px' }}>
                            {options.map(option => (
                                <FormControlLabel
                                    key={option.value}
                                    color='success'
                                    style={{ margin: '0px' }}
                                    control={
                                        <Checkbox
                                            checked={selectedBrands.includes(option.value)}
                                            onChange={handleCheckboxChange}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </FormGroup>
                    </div>
                    <hr></hr>
                    <div id="price-filter" style={{marginLeft: '5%', marginRight: '5%', marginTop: '2.5%'}}>
                        <h5 style={{ color: 'green', fontWeight: '300' }}>
                            {language === 'english' ? 'Price' : 'الماركات'}
                        </h5>
                        <Box>
                            <span></span>
                            <Slider
                                value={priceRange}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={maxPrice}
                                step={1}
                                marks
                                sx={{ color: 'green' }}
                            />
                        </Box>
                    </div>
                </aside>
                <aside id="productsAside">
                    <div className="category-container">
                        {Array.from({ length: totalProducts }).map((_, index) => (
                            <Box key={index} sx={{ pt: 0.5, display: loading ? 'block' : 'none' }}>
                                <Skeleton variant="rectangular" width={210} height={118} />
                                <Skeleton />
                                <Skeleton width="60%" />
                            </Box>
                        ))}
                        {error ? (
                            <div>Error: {error}</div>
                        ) : (
                            filteredData.map(product => (
                                <ProductItem key={product._id} product={product} nb_in_stock={product.nb_in_stock} language={language}/>
                            ))
                        )}
                    </div>
                    <div id='category-pagination'>
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                variant="outlined"
                                color="primary"
                            />
                        </Stack>
                    </div>
                </aside>
            </section>
        </div>
    );
}