export const removeFromCart = async (productId, csrfToken, callback) => {
      try {
          const response = await fetch('/api/user/cart', {
              method: "DELETE",
              headers: {
                  "Content-Type": "application/json",
                  'CSRF-Token': csrfToken
              },
              body: JSON.stringify({ productId })
          });
          const data = await response.json();
          if (data.success) {
              callback();
          } else {
              // error notify user
          }
      } catch (error) {
          // error notify user
      }
};

export const clearLocalCart = async (csrfToken, callback) => {
    try {
      const response = await fetch('/api/user/clearCart', {
        method: "PUT",
        headers: {
          'CSRF-Token': csrfToken
        },
        credentials: 'include'
      });
      const data = await response.json();
      if (data.success) {
          callback();
      } else {
        // error notify user
      }
    } catch (error) {
      // error notify user
    }
};
  