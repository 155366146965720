import '../css/Product.css'
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { getCartLength } from "../utils/getCartLength";
import { addToCart } from "../utils/addToCart";
import { addToWishlist } from "../utils/addToWishlist";
import { removeFromWishlist } from "../utils/removeFromWishlist";
import { useNavigate } from 'react-router-dom';
import SessionAlert from '../components/SessionAlert'
import CircularIndeterminate from '../components/CircularLoad';
import SimpleAlert from '../components/SimpleAlert';
import Cookies from 'js-cookie';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from 'react-bootstrap/Table';
import ReactGA from 'react-ga4';
import useCsrfToken from '../useCsrfToken';
import { getWishlistLength } from "../utils/getWishlistLength";
import { useCart } from '../CartContext';
import { useWishlist } from '../WishlistContext';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default function Product(){
    const [authenticated, setAuthenticated] = useState(null);
    const [user, setUser] = useState(null);
    const [product, setProduct] = useState(null);
    const [addedToWishlist, setAddedToWishlist] = useState(false);
    // const [loggedinAlertDisplay, setLoggedinAlertDisplay] = useState('none');
    const [addToCartDisplay, setAddToCartDisplay] = useState('none');
    const [addToWishlistDisplay, setAddToWishlistDisplay] = useState('none');
    const [wishlistMessage, setWishlistMessage] = useState('');
    const [wishlistAlertType, setWishlistAlertType] = useState('success');
    const [display, setDisplay] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [language, setLanguage] = useState('english');
    const [quantity, setQuantity] = useState(1);
    const csrfToken = useCsrfToken();
    const { setCartLength } = useCart();
    const { setWishlistLength } = useWishlist();
    const { id } = useParams();

    const handleAddToWishlist = async () => {
        setDisplay(true);
        const response = await addToWishlist(product.id, csrfToken);
        if(response.success) {
            setAddedToWishlist(true);
            // setWishlistAlertType('success')
        }
        else {
            // setWishlistAlertType('error')
        }
        // setAddToWishlistDisplay('block');
        // setWishlistMessage(response.message)
        alert(response.message)
        // Fetch the updated wishlist length
        const length = await getWishlistLength();
        setWishlistLength(length);
    };

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };
    
    const handleAddToCart = async () => {
        setDisplay(true);
        addToCart(product.id, product.english_name, product.price, product.category, quantity, csrfToken, async() => {
            setAddToCartDisplay('block');
            // Fetch the updated cart length
            alert('Added to cart')
            const length = await getCartLength();
            setCartLength(length)
        });
    };

    const handleRemoveFromWishlist = () => {
        setDisplay(true);
        removeFromWishlist(product.id, csrfToken, async() => {
            setAddedToWishlist(false);
            // Fetch the updated wishlist length
            const length = await getWishlistLength();
            setWishlistLength(length)
        });
    };

    useEffect(() => {
        if (Cookies.get('language')) {
            setLanguage(Cookies.get('language'));
        } else {
            Cookies.set('language', 'english');
            setLanguage('english');
        }
        fetchUserStatus();
        fetchProduct();
    }, []);
  
    const fetchUserStatus = async () => {
      try {
        const response = await fetch('/api/user/status');
        if (response.ok) {
          const results = await response.json();
          setAuthenticated(results.authenticated)
          setUser(results.user)
        } else {
          console.error('Failed to fetch user');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const fetchProduct = async () => {
        try {
          const response = await fetch(`/api/product/${id}`);
          if (response.ok) {
            const data = await response.json();
            setProduct(data.product)
            let product = data.product;
            setSelectedImage(product.images[0].url);
            ReactGA.event('view_item', {
                currency: 'USD',
                value: product.price,
                items: [{
                  item_id: product._id,
                  item_name: product.english_name,
                  affiliation: 'Online Store',
                  price: parseInt(product.price, 10),
                  item_category: product.category
                }]
            });
            setAddedToWishlist(data.addedToWishlist)
          } else {
            console.error('Failed to fetch product');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const changeSelectedImage = (event) => {
        const image = event.target.src
        setSelectedImage(image); // Update selected image in state
    };

    if (authenticated === null && user === null) {
        return (
            <div id="secondary-loading">
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    if (!product) {
        return (
            <div id="secondary-loading">
                <CircularIndeterminate display={true}/>
            </div>
        )
    }

    const discountedPrice = product.discount > 0 ? product.price * (1 - product.discount / 100) : product.price;
    
    return (
        <div dir={language === 'arabic' ? 'rtl' : 'ltr'} lang={language === 'arabic' ? 'ar' : 'en'}>
            <SessionAlert/>
            {/* <SimpleAlert key="message2-alert" message="Product added to cart" type="success" display={addToCartDisplay}/>
            <SimpleAlert key="message3-alert" message={wishlistMessage} type={wishlistAlertType} display={addToWishlistDisplay}/> */}
            <section className="product-container">
                <div className="left-child">
                    <div className='smallproduct-images'>
                        {product.images.map((img, i) => (
                            <img 
                            className={`smallproduct-image ${selectedImage === img.url ? 'selected' : ''}`}
                            key={i}
                            onClick={changeSelectedImage}
                            loading="lazy"
                            src={`${img.url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')}`}
                            srcSet={`
                                ${img.url.replace('/upload/', '/upload/c_scale,w_200,q_auto,f_auto/')} 200w, 
                                ${img.url.replace('/upload/', '/upload/c_scale,w_400,q_auto,f_auto/')} 400w, 
                                ${img.url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')} 800w
                            `}
                            alt="Small Product" 
                            />
                        ))}
                    </div>
                    <div id="bigproduct-image">
                        <div id="productCarousel" className="carousel slide" data-ride="carousel">
                            <div className="carousel-inner">
                                <img
                                className="d-block w-100"
                                src={`${selectedImage.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')}`}
                                loading="lazy" 
                                srcSet={`
                                    ${selectedImage.replace('/upload/', '/upload/c_scale,w_200,q_auto,f_auto/')} 200w, 
                                    ${selectedImage.replace('/upload/', '/upload/c_scale,w_400,q_auto,f_auto/')} 400w, 
                                    ${selectedImage.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')} 800w
                                `} 
                                sizes="(max-width: 600px) 200px, (max-width: 1200px) 400px, 800px" 
                                alt="Producte" 
                                />
                            </div>
                        </div>
                    </div>
                    <div className='smallproductmobile-images'>
                        {product.images.map((img, i) => (
                            <img 
                            className={`smallproductmobile-image ${selectedImage === img.url ? 'selected' : ''}`}
                            key={i}
                            onClick={changeSelectedImage}
                            loading="lazy"
                            src={`${img.url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')}`}
                            srcSet={`
                                ${img.url.replace('/upload/', '/upload/c_scale,w_200,q_auto,f_auto/')} 200w, 
                                ${img.url.replace('/upload/', '/upload/c_scale,w_400,q_auto,f_auto/')} 400w, 
                                ${img.url.replace('/upload/', '/upload/c_scale,w_800,q_auto,f_auto/')} 800w
                            `}
                            alt="Small Product" 
                            />
                        ))}
                    </div>
                </div>

                <div className="right-child">
                    <div id="top-panel">
                        <h1 style={{ margin: '20px 0 20px', fontSize: '22px', fontWeight: '500', textAlign: 'center', color: 'green' }}>
                        {language === 'english' ? product.english_name : product.arabic_name}
                        </h1>
                    </div>
                    <div id="middle-panel">
                        <div className='middlepanel-section' style={{borderBottom: '1px solid rgb(227, 225, 225)'}}>
                            <span className='productmiddle-left'><LocalOfferIcon style={{color: 'green', marginRight: '10px'}}/>Brand:</span>
                            <span className='productmiddle-right'>{product.brand}</span>
                        </div>
                        <div className='middlepanel-section'>
                            <span className='productmiddle-left'><InventoryIcon style={{color: 'green', marginRight: '10px'}}/>Left in stock:</span>
                            <span className='productmiddle-right'>{product.nb_in_stock} pieces</span>
                        </div>
                        <div className='middlepanel-section' style={{borderTop: '1px solid rgb(227, 225, 225)'}}>
                            <span className='productmiddle-left'><AttachMoneyIcon style={{color: 'green', marginRight: '10px'}}/>Price:</span>
                            <div className='middlepanel-price' style={{display: 'flex', marginLeft: 'auto', fontSize: '18px'}}>
                                <span style={{display: product.discount > 0 ? 'block' : 'none', color: 'red', textDecoration: 'line-through'}}>${product.price}</span>
                                <span className='productmiddle-right' style={{marginLeft: '10px'}}>${discountedPrice}</span>
                            </div>
                        </div>
                    </div>
                    <div id='cartwish-buttons'>
                        <div>
                            <Select
                                id="quantity-to-order"
                                label="Quantity"
                                value={quantity}
                                onChange={handleQuantityChange}
                                style={{width: '95%', height: '100%'}}
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((quantity, index) => (
                                    <MenuItem key={quantity} value={quantity}>
                                        {quantity}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <Button 
                                variant="contained" 
                                className='cart-button' 
                                onClick={handleAddToCart} 
                                style={{display: product.nb_in_stock > 0 ? 'block' : 'none', fontSize: '15px', backgroundColor: 'green', textTransform: 'none'}}>
                                    Add to cart
                            </Button>
                            <Button 
                                variant="contained" 
                                className='cart-button' 
                                style={{display: product.nb_in_stock === 0 ? 'block' : 'none', fontSize: '15px', backgroundColor: 'grey', textTransform: 'none'}}>
                                    Out of stock
                            </Button>
                        </div>
                        <div>
                            <Button 
                                variant="contained" 
                                className='wishlist-button' 
                                onClick={addedToWishlist ? handleRemoveFromWishlist : handleAddToWishlist} 
                                style={{fontSize: addedToWishlist ? '15px' : '15px', backgroundColor: addedToWishlist ? 'red' : 'green', textTransform: 'none'}}>
                                    {addedToWishlist ? 'Remove from wishlist' : 'Add to wishlist'}
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product-overview">
                <div className='container'>
                    <div className='top-panel'>
                        <span style={{fontWeight: 'bold', fontSize: '20px', color: '#00cd7c', borderBottom: '3px solid #00cd7c'}}>Specifications</span>
                    </div>
                    <div className='bottom-panel' style={{marginTop: '20px'}}>
                        <div className='table' style={{width: '100%'}}>
                            <Table bordered>
                                <tbody>
                                    {/* BRAND */}
                                    <tr style={{borderTop: 'none'}} >
                                        <td style={{borderRight: 'none'}}>
                                            Brand
                                        </td>
                                        <td style={{borderRight: 'none'}}>
                                            {product.brand}
                                        </td>
                                    </tr>
                                    {/* DESCRIPTION */}
                                    <tr style={{borderTop: 'none'}} >
                                        <td style={{borderRight: 'none'}}>
                                            Description
                                        </td>
                                        <td style={{borderRight: 'none'}}>
                                            {product.english_description}
                                        </td>
                                    </tr>
                                    {/* CATEGORY */}
                                    <tr style={{borderTop: 'none'}} >
                                        <td style={{borderRight: 'none'}}>
                                            Item code
                                        </td>
                                        <td style={{borderRight: 'none'}}>
                                            {product.code}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}