import ReactGA from 'react-ga4';

export const addToCart = async (productId, productName, productPrice, productCategory, quantity = 1, csrfToken, callback) => {
  try {
      const response = await fetch('/api/user/cart', {
          method: "PUT",
          headers: {
              "Content-Type": "application/json",
              'CSRF-Token': csrfToken
          },
          body: JSON.stringify({ productId, quantity }),
          credentials: 'include'
      });

      if (response.ok) {
          callback();
          ReactGA.event('add_to_cart', {
              currency: 'USD',
              value: parseInt(productPrice, 10) * quantity,
              items: [{
                  item_id: productId,
                  item_name: productName,
                  affiliation: 'Online Store',
                  price: productPrice,
                  quantity: quantity,
                  item_category: productCategory
              }]
          });
      } else {
          console.error("Failed to add item to cart");
      }
  } catch (error) {
      console.error('Error:', error);
  }
};