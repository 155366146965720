import { useState, useEffect } from 'react';

const useAuthStatus = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserStatus = async () => {
            try {
                const response = await fetch('/api/user/status');
                if (response.ok) {
                    const results = await response.json();
                    setAuthenticated(results.authenticated);
                    setUser(results.user);
                } else {
                    console.error('Failed to fetch user');
                }
            } catch (error) {
                console.error('Error:', error);
            }
            setLoading(false);
        };

        fetchUserStatus();
    }, []);

    return { authenticated, user, loading };
};

export default useAuthStatus;